import React from 'react'
import navigate from '../utils/navigate'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'

export default function New_Leave_Request_Covid_Result_Yes() {
  const onSubmit = (event) => {
    event.preventDefault()
    navigate('/New_Leave_Request_step1/');
  }

  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Covid Leave Request Family Result" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            <p>Thank you for submitting your COVID Information</p>
          </div>
          <form className="LeaveFormEntry" onSubmit={onSubmit}>
            <h3>Results:</h3>
            <div className="formDiv">
              <label>
                Thank you for your information. Please click the continue button below to complete your leave of
                absence request. Please be sure to email a copy of your COVID test results to the leave center at:&nbsp;
                <a
                  href="mailto:SmileBrandsLeave@ControlSourceInc.com"
                >
                  SmileBrandsLeave@ControlSourceInc.com
                </a>
                .&nbsp;&nbsp;
              </label>
            </div>
            <br className="clear" />
            <p className="clear">&nbsp;</p>
            <input type="submit" id="setp1submit" value="Continue &raquo;" />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
